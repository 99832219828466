<script lang="ts">
  import { onMount } from "svelte";
  import { clamp } from "../utils";
  import airdancerPostcardImg from "../../../assets/img/airdancer_postcard.webp";
  import Badge from "../../client/common/Badge.svelte";
  let foregroundRef: HTMLAnchorElement;
  let backgroundRef: HTMLDivElement;

  let progress = $state(0);

  const update = () => {
    const bounds = backgroundRef.getBoundingClientRect();
    if (bounds.top < 0) {
      progress = Math.abs(bounds.top) / (bounds.height - window.innerHeight);
      progress = clamp(progress, 0, 1);
    } else {
      progress = 0;
    }
  };

  onMount(() => {
    const scrollContainer = document.querySelector("main");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          update();
          scrollContainer?.addEventListener("scroll", update, { passive: true });
          scrollContainer?.addEventListener("resize", update, { passive: true });
        } else {
          update();
          scrollContainer?.removeEventListener("resize", update);
          scrollContainer?.removeEventListener("scroll", update);
        }
      });
    });
    observer.observe(foregroundRef);

    return () => {
      observer.disconnect();
      scrollContainer?.removeEventListener("resize", update);
      scrollContainer?.removeEventListener("scroll", update);
    };
  });
</script>

<div
  bind:this={backgroundRef}
  class="background"
  style={`--airdancer-rotation: ${progress * 360}deg;`}
>
  <a
    href="/typefaces/airdancer"
    bind:this={foregroundRef}
    class="content-wrapper"
  >
    <div class="title-wrapper">
      <div class="title">G2 Airdancer</div>
    </div>
    <div class="badge-wrapper">
      <Badge position="bottom-left" --badge-scale="1.5">New Release!</Badge>
    </div>
    <img
      src={airdancerPostcardImg.src}
      width={airdancerPostcardImg.width}
      height={airdancerPostcardImg.height}
      alt="Airdancer Postcard"
    />
  </a>
</div>

<style>
  .background {
    position: relative;
    background: #323232;
    width: 100%;
    height: 300vh;
    display: flex;
    justify-content: center;
  }

  .content-wrapper {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-origin: center;
    transform: rotate(var(--airdancer-rotation));
    /* transition: transform 100ms; */
    will-change: transform;
  }

  .title-wrapper {
    position: absolute;
    z-index: 5;
    top: var(--main-margin);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: var(--main-margin);
  }

  .title {
    display: block;
    background: #323232;
    color: white;
    border: var(--line-width) solid white;
    padding: var(--button-padding-y) var(--button-padding-x);
    text-decoration: none;
    user-select: none;
    border-radius: 10px;
  }

  a:hover {
    opacity: 1;

    & .title {
      background: white;
      color: #323232;
    }
  }

  .badge-wrapper {
    position: absolute;
    pointer-events: none;
    width: 100%;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 10;
    height: 600px;
  }
</style>
